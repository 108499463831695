import api from '../../api'
import * as types from './types'

let timeoutId = null;
const REQUEST_TIMEOUT = 120000;
const INTERVAL_REQUEST_TIMEOUT = 500;

export default {
  loadInitialState({commit}, data) {
    commit(types.LOAD_STATE, data);
  },
  setMenu({commit}, data) {
    commit(types.SET_MENU_ID, data);
  },
  setMenuLevel({commit}, data) {
    commit(types.SET_MENU_LEVEL, data);
  },
  loadData({commit, dispatch, state}, options) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(function() {
      let request = api.get(state.taxonomies_url, {timeout: REQUEST_TIMEOUT, abortingXHR: true});

      request.then((response) => {
        if (response !== undefined) { response['vueMeta'] = options; } // [TODO] - temporary until https://github.com/vuejs/vuex/pull/1043
        commit(types.LOAD_STATE, response);
      }).then(() => {
        commit(types.SET_LOADING, false);
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, INTERVAL_REQUEST_TIMEOUT);
  },
  changeLocale({commit, dispatch, state}, data) {
    if (state.locale !== data.locale && data.locale.length > 0) {
      let url = `${state.base_url}/locale/set`;
      let request = api.post(url, {locale: data.locale});
      request.then((response) => {
        if(response) window.location.reload(true);
      });
    }
  },
  changeCurrency({commit, dispatch, state}, data) {
    if (state.currency !== data.currency && data.currency.length > 0) {
      let url = `${state.base_url}/currency/set`;
      let request = api.get(url, {currency: data.currency});
      request.then((response) => {
        if(response) window.location.reload(true);
      });
    }
  }
}
